@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.sidebar {
  flex: 0 0 400px;
  max-width: 400px;
  background: $primary-color;
  padding: 40px;
  display: flex;
  flex-direction: column;
  .logo {
    height: 50px;
    width: fit-content;
  }
  .menu {
    flex: 1;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .menuItem {
      height: 40px;
      display: flex;
      align-items: center;
      color: white;
      font-size: 20px;
      margin: 30px 0;
      cursor: pointer;
      transition: all 400ms ease;
      &:hover {
        font-weight: 500;
        font-size: 25px;
      }
      &.true {
        font-weight: 500;
        font-size: 28px;
      }
    }
  }
}
