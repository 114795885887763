.main-logo{
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
}

@media screen and (max-width:600px) {
    .nvest-icon{
        width: 238px;
        height: 45px;
    }    
}

