@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

@import "_colors";
@import "mixin";

body {
  font-family: Montserrat !important;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  #root {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    .switch-wrapper {
      position: relative;
    }

    .switch-wrapper > div {
      position: absolute;
      display: flex;
      flex-direction: column;
    }
    a {
      text-decoration: none;
    }
    * {
      font-family: Montserrat;
      outline: none;
      box-sizing: border-box;
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
