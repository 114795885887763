@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.mainLayout {
  height: 0;
  flex: 1;
  display: flex;
  background: white;
  .mainContent {
    width: 0;
    flex: 1;
  }
}
